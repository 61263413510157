<template>
  <div class="about">
    <Headbar title="关于我们"></Headbar>

    <div class="content" v-html="articl"></div>
  </div>
</template>

<script>
import CustomComponent from "@/components/CustomComponent";
import { ref } from "vue";

export default {
  name: '',
  components: {},
  setup() {
    return {
      articl: ref("")
    }
  },
  created() {
    this.getArticle()
  },
  methods: {
    getArticle() {
      let _this = this
      this.$request.post(
        "api/Article/Get",
        {
          select_id: 1
        },
        (res) => {
          if (res.data.code == 0) {
						_this.$q.dialog({
							component: CustomComponent,
							componentProps: {
								messages: [res.data.msg],
								persistent: true,
							}
						});
						return
					}
          let data = res.data.data
          _this.articl = data.articlecontent
        }
      )
    }
  }
}
</script>

<style scoped>
.content {
  padding: 10px;
  color: #fff;
}
</style>